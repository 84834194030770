import React from "react"
import { Link } from "gatsby"
import { useIntl, FormattedMessage } from "react-intl"

import { LocaleSwitcher } from "../../intl"

const Footer = ({ showLocaleSwitcher = true }) => {

  const intl = useIntl()

  return (
  <footer className="footer">
    <div className="container">
      {showLocaleSwitcher && <div className="mb-5">
        <LocaleSwitcher />
      </div>}

      © {new Date().getFullYear()}
      {` `}
      CustomerRadius
      {` `}
      <Link to={intl.formatMessage({ id: 'url.about' })}>
        <FormattedMessage id="footer.about" />
      </Link>
      {` `}
      &middot;
      {` `}
      <Link to={intl.formatMessage({ id: 'url.products-services' })}>
        <FormattedMessage id="footer.products_services" />
      </Link>
      {` `}
      &middot;
      {` `}
      <Link to={intl.formatMessage({ id: 'url.contact' })}>
        <FormattedMessage id="footer.contact" />
      </Link>
      {` `}
      &middot;
      {` `}
      <Link to={intl.formatMessage({ id: 'url.terms' })}>
        <FormattedMessage id="footer.terms" />
      </Link>
      {` `}
      &middot;
      {` `}
      <Link to={intl.formatMessage({ id: 'url.privacy' })}>
        <FormattedMessage id="footer.privacy" />
      </Link>
    </div>
  </footer>
)}

export default Footer