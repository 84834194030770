import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faCheckSquare, faCheckCircle, faAngleDown, faArrowRight, faSpinner,
  faUserCog, faColumns, faAddressCard, faMailBulk, faPaperPlane, faSignOutAlt,
  faArrowLeft, faPlus, faEdit, faTimes, faArrowCircleRight, faExclamationCircle,
  faMapMarkerAlt, faCloudUploadAlt, faCreditCard, faGlobe, faMobileAlt,
  faArrowDown, faStar, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

library.add(faCheck, faCheckSquare, faCheckCircle, faAngleDown, faArrowRight, faSpinner,
  faUserCog, faColumns, faAddressCard, faMailBulk, faPaperPlane, faSignOutAlt,
  faArrowLeft, faPlus, faEdit, faTimes, faArrowCircleRight, faExclamationCircle,
  faMapMarkerAlt, faCloudUploadAlt, faCreditCard, faGlobe, faMobileAlt, 
  faArrowDown, faStar, faExclamationTriangle,
  faTwitter)