import React, { useEffect } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl, FormattedMessage } from "react-intl"

import { LocaleSwitcher } from "../../intl"

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = ({ showLocaleSwitcher = true }) => {

  const intl = useIntl()

  const data =  useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "customer-radius-logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 55) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    let burger = document.querySelector('.burger')
    let nav = document.querySelector('#'+burger.dataset.target)

    const burgerListener = (event) => {
      burger.classList.toggle('is-active');
      nav.classList.toggle('is-active');
    }

    burger.addEventListener('click', burgerListener)

    return () => {
      burger.removeEventListener('click', burgerListener)
    }
  }, [])

  return (
    <header>
      <nav className="navbar is-fixed-top is-transparent is-dark">
        <div className="container">
        <div className="navbar-brand">
          <Link to={intl.formatMessage({ id: 'url.home' })} className="navbar-item has-text-centered">
            <Img fluid={data.logo.childImageSharp.fluid} className="image is-32x32"/>&nbsp;CustomerRadius
          </Link>
          <span role="button" className="burger navbar-burger" aria-label="menu" aria-expanded="false" data-target="navMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>
        <div id="navMenu" className="navbar-menu">
          <div className="navbar-end">
            <Link to={intl.formatMessage({ id: 'url.map-my-customers' })} className="navbar-item">
              <FormattedMessage id="nav.map_my_customers" />
            </Link>
            <Link to={intl.formatMessage({ id: 'url.products-services' })} className="navbar-item">
              <FormattedMessage id="nav.products_services" />
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <span className="navbar-link">
                <FormattedMessage id="nav.more" />
              </span>
              <div className="navbar-dropdown is-boxed">
                <Link to={intl.formatMessage({ id: 'url.about' })} className="navbar-item">
                  <FormattedMessage id="nav.about" />
                </Link>
                <Link to={intl.formatMessage({ id: 'url.contact' })} className="navbar-item">
                  <FormattedMessage id="nav.contact" />
                </Link>
                
                {/* <hr className="navbar-divider" />
                {data.articles.nodes.map((node) => {
                  return (
                    <Link to={"/"+node.frontmatter.slug} key={node.frontmatter.slug} className="navbar-item">
                      {node.frontmatter.title.substring(0, 25)+"..."}
                    </Link>
                  )
                })} */}
              </div>
            </div>
            {/* <Link to="/login" className="navbar-item">Log In</Link> */}
            {/* <Link to="/signup" className="navbar-item">Sign Up</Link> */}
            <div className="navbar-item">
              <div className="buttons">
                <Link to={intl.formatMessage({ id: "url.earlyaccess" })}>
                  <button className="button is-primary is-rounded">
                    <strong><FormattedMessage id="nav.join_early_access" /></strong>
                  </button>
                </Link>
              </div>
            </div>
            {showLocaleSwitcher && <>
              <div className="navbar-item is-hidden-touch">
                <LocaleSwitcher globeOnly={true} isRight={true} isDark={true} />
              </div>
              <div className="navbar-item is-hidden-desktop">
                <LocaleSwitcher globeOnly={true} />
              </div>
            </>}
          </div>
        </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
