import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import "./layout.scss"
import "./fa-icons"

const Layout = ({ header = true, footer = true, container = true, showLocaleSwitcher = true, children }) => {
  return (
    <>
      <Helmet>
        <html className="has-background-white-bis" lang="en"/>
        <body className="has-navbar-fixed-top has-background-white" />
      </Helmet>
      {header && <Header showLocaleSwitcher={showLocaleSwitcher} />}
      <div className={ container ? "container" : ""}>
        <main>{children}</main>
      </div>
      {footer && <Footer showLocaleSwitcher={showLocaleSwitcher} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
